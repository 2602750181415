* {
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  background: #e9e9e9;
}
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

li {
  list-style: none;
}
