footer {
  background-image: url(../../img/footer-bg.png);
  padding-top: 50px;
  .footer-details {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .footer-list {
      position: relative;
      z-index: 10;
      padding: 20px 20px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 10px #0000001a;

      li {
        margin: 10px;
        display: flex;
        align-items: center;

        span {
          margin-right: 15px;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          padding: 15px;
          align-items: center;
          justify-content: center;
          display: flex;
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
          color: #f1556a;
        }
      }
    }

    .footer-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      .logo {
        width: 200px;
        height: 200px;
      }

      .social-media {
        display: flex;

        .icon {
          position: relative;
          background-color: #ffffff;
          border-radius: 50%;
          padding: 15px;
          margin: 10px;
          width: 60px;
          height: 60px;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

          a {
            color: black;
          }

          &:hover {
            .tooltip {
              top: -45px;
              opacity: 1;
              visibility: visible;
              pointer-events: auto;
              text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
            }
            span {
              text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }
        .tooltip {
          position: absolute;
          top: 0;
          font-size: 14px;
          background-color: #ffffff;
          color: #ffffff;
          padding: 5px 8px;
          border-radius: 5px;
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
          opacity: 0;
          pointer-events: none;
          transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

          &::before {
            position: absolute;
            content: "";
            height: 8px;
            width: 8px;
            background-color: #ffffff;
            bottom: -3px;
            left: 50%;
            transform: translate(-50%) rotate(45deg);
            transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          }
        }
        .facebook:hover {
          background-color: #3b5999;
          color: #ffffff;
          .tooltip,
          .tooltip::before {
            background-color: #3b5999;
            color: #ffffff;
          }
        }

        .instagram:hover {
          background-color: #e1306c;
          color: #ffffff;
          .tooltip,
          .tooltip::before {
            background-color: #e1306c;
            color: #ffffff;
          }
        }

        .whatsapp:hover {
          background-color: #00bb2d;
          color: #ffffff;
          .tooltip,
          .tooltip::before {
            background-color: #00bb2d;
            color: #ffffff;
          }
          a {
            color: #ffffff;
          }
        }
        .tiktok:hover {
          background-color: black;
          color: #ffffff;
          .tooltip,
          .tooltip::before {
            background-color: black;
            color: #ffffff;
          }
          a {
            color: #ffffff;
          }
        }
      }
    }
  }

  .sub-footer {
    padding: 20px 0px;
    border-top: 1px solid rgba(250, 250, 250, 0.3);
    text-align: center;
    width: 70%;
    margin: 0 auto;

    p {
      color: #fff;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 0.5px;

      a {
        color: white;
        font-weight: 600;
        &:hover {
          color: black;
        }
      }
    }
  }

  // 841px
  @media (max-width: 841px) {
    .footer-details {
      flex-direction: column;
    }
  }
}
