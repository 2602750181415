:root {
  --gap: 8px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--gap);
  padding: var(--gap);
  height: 100vh;
  position: relative;
  background: rgb(8, 11, 17);
  //Prefixes
  display: -ms-grid;
  width: 100%;

  & div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: calc(var(--gap) * 1.5);
    min-height: 120px;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    background-color: teal;
    background-image: url("../../img/wepik14148.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition-property: filter, backdrop-filter, opacity, color;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    //Prefixes
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-transition-property: opacity, color, -webkit-filter,
      -webkit-backdrop-filter;
    transition-property: opacity, color, -webkit-filter, -webkit-backdrop-filter;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;

    span {
      margin-bottom: 15px;
      color: tomato;
    }

    & span:first-of-type {
      z-index: 2;
    }

    & .overlay {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: linear-gradient(180deg, #00000000 5%, #000000cc);
      backdrop-filter: blur(6px);
      transition-property: opacity;
      transition-duration: 0.4s;
      transition-timing-function: ease-out;
      z-index: -1;
      //Prefixes
      background: -webkit-linear-gradient(top, #00000000 5%, #000000cc);
      -webkit-backdrop-filter: blur(6px);
      -webkit-transition-property: opacity;
      -webkit-transition-duration: 0.4s;
      -webkit-transition-timing-function: ease-out;
    }

    & .shop {
      display: block;
      color: tomato;
      font-size: 0.8rem;
      font-weight: 400;
      margin-top: 8px;
      margin-bottom: -25px;
      opacity: 0;
      transition-property: margin-bottom, opacity;
      transition-duration: 0.3s;
      transition-timing-function: ethiopic-halehame-om-et;
      z-index: 2;
      //Prefixes
      -webkit-transition-property: margin-bottom, opacity;
      -webkit-transition-duration: 0.3s;
      -webkit-transition-timing-function: ethiopic-halehame-om-et;
    }

    &:hover > .overlay {
      opacity: 1;
    }

    &:hover {
      filter: brightness(1.2);
      -webkit-filter: brightness(1.2);
    }

    &:hover > .shop {
      opacity: 1;
      margin-bottom: 0;
    }
  }

  & > div:nth-of-type(1) {
    grid-column: 1/3;
  }

  & > div:nth-of-type(2) {
    grid-column: 3/5;
    grid-row: 1/3;
  }

  & > div:nth-of-type(4) {
    grid-column: 2/3;
    grid-row: 2/5;
  }
  & > div:last-child {
    grid-column: 3/5;
  }
}

@media screen and (max-width: 840px) {
  .grid {
    height: auto;
    grid-template-columns: repeat(2, 1fr);

    & div {
      // min-height: auto;
      // aspect-ratio: 1 / 1;
      width: 100%;
      background-size: cover;
      background-position: top;
      -webkit-background-size: 1601px 1600px;
      background-image: url("../../img/arrangement-with-empty-notepad-on-desk.jpg");
    }

    & > div:nth-of-type(1) {
      grid-column: auto;
    }

    & > div:nth-of-type(2) {
      grid-column: auto;
      grid-row: auto;
    }

    & > div:nth-of-type(4) {
      grid-column: auto;
      grid-row: auto;
    }
    & > div:last-child {
      grid-column: 1/3;
    }
  }
}
