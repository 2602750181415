.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container {
  min-height: calc(100vh - 436px);
  overflow: hidden;

  h3 {
    font-size: 30px;
    padding: 0 50px;
    span {
      color: #f1556a;
    }
  }
  .waves-container {
    position: relative;
    text-align: center;
    background: linear-gradient(60deg, #f48040 0%, #f1556a 100%);
    background-image: url("../../img/wepik-2022230-162454.jpg");
    background-position: center;
    background-size: cover;
    color: white;

    .inner-header {
      height: 78vh;
      width: 100%;
      margin: 0;
      padding: 0;
      &.flex {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        line-height: 2;
        .wrapper {
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          z-index: 2;
          width: 80%;
          display: flex;
          flex-direction: column;

          .text {
            z-index: 2;
            color: white;
            font-family: "Poppins", sans-serif;
            font-size: 35px;
            font-weight: 650;
            letter-spacing: 1px;
            text-align: center;
            .disappear {
              font-size: 30px;
              margin: auto;
              text-align: center;
              font-weight: 700;
              letter-spacing: 2px;
              font-size: 55px;
              color: black;

              & span {
                display: inline-block;
                color: transparent;
                text-shadow: 0px 0px 0px black;
                animation-duration: 3s;

                &:nth-child(1) {
                  animation-name: disappearleft;
                }
                &:nth-child(2) {
                  animation-name: disappearleft;
                  animation-delay: 0.3s;
                }
                &:nth-child(3) {
                  animation-name: disappearight;
                  animation-delay: 0.6s;
                }
                &:nth-child(4) {
                  animation-name: disappearleft;
                  animation-delay: 0.8s;
                }
                &:nth-child(5) {
                  animation-name: disappearight;
                  animation-delay: 1s;
                }
                &:nth-child(6) {
                  animation-name: disappearight;
                  animation-delay: 1.3s;
                }
                &:nth-child(7) {
                  animation-name: disappearleft;
                  animation-delay: 1.6s;
                }
                &:nth-child(8) {
                  animation-name: disappearight;
                  animation-delay: 2s;
                }
                &:nth-child(9) {
                  animation-name: disappearight;
                  animation-delay: 2.3s;
                }
                @keyframes disappearleft {
                  50% {
                    transform: skew(50deg) translateY(0);
                    text-shadow: 0px 0px 50px;
                    opacity: 0;
                  }
                }
                @keyframes disappearight {
                  50% {
                    transform: skew(-50deg);
                    text-shadow: 0px 0px 50px;
                    opacity: 0;
                  }
                }
              }
            }
          }

          .sub {
            z-index: 2;
            color: white;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 1px;
            text-align: center;
          }
          .main-button {
            font-size: 14px;
            border-radius: 25px;
            padding: 15px 25px;
            background-color: #fba70b;
            text-transform: uppercase;
            color: #fff;
            font-weight: 600;
            letter-spacing: 1px;
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            box-shadow: 0px 0px 10px #0000001a;
            margin-top: 20px;
            width: 20%;
            margin: 40px auto 0 auto;
            @media (max-width: 1050px) {
              width: 25%;
            }
            @media (max-width: 900px) {
              width: 30%;
            }
            @media (max-width: 760px) {
              width: 35%;
            }
            @media (max-width: 630px) {
              width: 55%;
            }

            &:hover {
              background-color: #f1556a;
            }
          }
        }
        .gradient {
          margin: 0;
          padding: 0;
          min-width: 100vw;
          min-height: 100vh;
          background: linear-gradient(
            -45deg,
            var(#de6262),
            var(#ffb88c),
            var(#12c2e9),
            var(#c471ed),
            var(#f64f59)
          );
          background: linear-gradient(
            -45deg,
            #ee7752,
            #e73c7e,
            #23a6d5,
            #23d5ab
          );
          background-size: 500% 500%;
          animation: gradient 20s ease infinite;
          opacity: 0.5;
        }
      }
      .header {
        display: flex;
        align-items: center;

        .span {
          font-size: 20px;
          color: white;
        }

        @media (max-width: 970px) {
          flex-direction: column;
          .disappear :last-child {
            display: none;
          }
        }
      }
      p {
        color: white;
        width: 70%;
      }
      .logo {
        width: 50px;
        fill: white;
        padding-right: 15px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .waves {
      position: relative;
      width: 100%;
      height: 15vh;
      margin-bottom: -7px;
      min-height: 100px;
      max-height: 150px;
    }
  }

  #clientes,
  #about,
  #contacto {
    padding: 70px 55px;
  }

  #clientes {
    background-image: url("../../img/right-bg-decor.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }

  .form-container {
    background-image: url("../../img/left-bg-decor.png");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 70px 2px;
  }

  /* Animation */

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }

  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .content {
      height: 30vh;
    }
    h1 {
      font-size: 24px;
    }
    #about,
    #contacto,
    #clientes {
      padding: 20px 20px;
    }
    h3 {
      padding: 0;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
