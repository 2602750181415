// Main Colors
$accent: null;
$white: #ffffff;
$black: #000000;
$dark-gray: lighten($black, 20%);
$gray: lighten($black, 40%);
$light-gray: lighten($black, 60%);
$lighter-gray: lighten($black, 80%);

// Pen Settings
$primary: #363636;
$accent: #fba70b;
$max-width: 460px;
.container {
  position: relative;
  max-width: $max-width;
  width: 100%;
  margin: 0 auto 100px;

  &.active {
    .card {
      &:first-child {
        background: darken($white, 5%);
        margin: 0 15px;
      }

      &:nth-child(2) {
        background: darken($white, 2%);
        margin: 0 10px;
      }

      &.alt {
        top: 20px;
        right: 0;
        width: 100%;
        min-width: 100%;
        height: auto;
        border-radius: 5px;
        padding: 60px 0 40px;
        overflow: hidden;

        .input-container,
        .button-container {
          left: 0;
          opacity: 1;
          visibility: visible;
          transition: 0.3s ease;
        }

        .input-container {
          transition-delay: 0.4s;

          &:nth-child(2) {
            transition-delay: 0.5s;
          }

          &:nth-child(3) {
            transition-delay: 0.6s;
          }
        }

        .button-container {
          transition-delay: 0.7s;
        }
      }
    }
  }
  .card {
    position: relative;
    background: $white;
    border-radius: 5px;
    padding: 60px 0 40px 0;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.3s ease;

    &:first-child {
      background: darken($white, 2%);
      height: 10px;
      border-radius: 5px 5px 0 0;
      margin: 0 10px;
      padding: 0;
    }

    /* Inputs */
    .input-container {
      position: relative;
      margin: 0 35px 35px;

      input,
      textarea {
        outline: none;
        z-index: 1;
        position: relative;
        background: none;
        width: 100%;
        height: 60px;
        border: 0;
        color: #212121;
        font-size: 20px;
        font-weight: 400;

        &:focus {
          ~ label {
            color: #9d9d9d;
            transform: translate(-12%, -50%) scale(0.75);
          }

          ~ .bar {
            &:before,
            &:after {
              width: 50%;
            }
          }
        }

        &:valid {
          ~ label {
            color: #9d9d9d;
            transform: translate(-12%, -50%) scale(0.75);
          }
        }
      }
      textarea {
        height: 155px;
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        color: #757575;
        font-size: 15px;
        font-weight: 300;
        line-height: 60px;
        transition: (0.2s ease);
      }

      .bar {
        position: absolute;
        left: 0;
        bottom: 0;
        background: #757575;
        width: 100%;
        height: 1px;

        &:before,
        &:after {
          content: "";
          position: absolute;
          background: $accent;
          width: 0;
          height: 2px;
          transition: 0.2s ease;
        }

        &:before {
          left: 50%;
        }

        &:after {
          right: 50%;
        }
      }
    }

    /* Button */
    .button-container {
      margin: 0 60px;
      text-align: center;

      button {
        outline: 0;
        cursor: pointer;
        position: relative;
        display: inline-block;
        background: 0;
        width: 150px;
        border: 2px solid #e3e3e3;
        padding: 10px 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
        overflow: hidden;
        transition: 0.3s ease;

        span {
          position: relative;
          z-index: 1;
          color: #ddd;
          transition: 0.3s ease;
        }

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          background: $accent;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          margin: -15px 0 0 -15px;
          opacity: 0;
          transition: 0.3s ease;
        }

        &:hover,
        &:active,
        &:focus {
          border-color: $accent;

          span {
            color: $accent;
          }
        }

        &:active,
        &:focus {
          span {
            color: $white;
          }

          &:before {
            opacity: 1;
            transform: (scale(10));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 90%;
  }
}

/* Card */
